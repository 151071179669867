@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'Circular Std', sans-serif;
}

@layer base {
  a {
    @apply text-teal-800;
  }
  :focus-visible, input:focus-visible {
    outline: 2px solid #4BC03A;
  }
  [type='text']:focus-visible,
  [type='email']:focus-visible,
  [type='url']:focus-visible,
  [type='password']:focus-visible,
  [type='number']:focus-visible,
  [type='date']:focus-visible,
  [type='datetime-local']:focus-visible,
  [type='month']:focus-visible,
  [type='search']:focus-visible,
  [type='tel']:focus-visible,
  [type='time']:focus-visible,
  [type='week']:focus-visible,
  [multiple]:focus-visible,
  textarea:focus-visible,
  select:focus-visible {
    --tw-ring-color: #4BC03A;
    border-color: var(--tw-ring-color);
  }

  [type="checkbox"],
  [type="radio"] {
    color: #4BC03A;
  }

  [type="checkbox"]:focus-visible,
  [type="radio"]:focus-visible {
    --tw-ring-color: #4bc03a;
  }

  button:focus-visible,
  a:focus-visible {
    box-shadow: 0 0 0 2px rgba(75, 192, 58, 0.5);
    border-color: #4bc03a;
    outline: none;
  }
}
